import asset from '@/utils/VaporUtil.js';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const preloaderMotion = {
    animate: {
        opacity: 0,
        transitionEnd: {
            display: 'none',
        },
    },
    transition: {
        delay: 2,
        duration: 0.5,
    },
};

const preloaderCircleMotion = {
    initial: {
        borderRadius: '50%',
        height: '0vh',
        width: '0%',
    },
    animate: {
        borderRadius: ['100%', '0%'],
        height: ['0vmax', '125vmax'],
        width: ['0vmax', '125vmax'],
    },
    transition: {
        times: [0.25, 0.5],
        duration: 2.25,
    },
};

const preloaderImageMotion = {
    initial: {
        scale: 0,
    },
    animate: {
        scale: [0, 1.2, 1, 1, 0],
    },
    transition: {
        delay: 0.5,
        times: [0.25, 0.5, 0.625, 0.75, 0.875],
        duration: 1.25,
    },
};

const PreloaderOverlay = () => (
    <StyledPreloader {...preloaderMotion}>
        <StyledPreloaderCircle {...preloaderCircleMotion}>
            <StyledPreloaderImage {...preloaderImageMotion} src={asset('assets/images/icon/black.svg')} />
        </StyledPreloaderCircle>
    </StyledPreloader>
);

const StyledPreloader = styled(motion.div)`
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    z-index: 101;
    background-color: ${({ theme }) => theme.colors.primary.base};
`;

const StyledPreloaderCircle = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 0vmax;
    width: 0vmax;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.primary.variant};
`;

const StyledPreloaderImage = styled(motion.img)`
    height: 5rem;
`;

export default PreloaderOverlay;
